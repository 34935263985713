import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/markdown-page.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "kcd-learning-clubs",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#kcd-learning-clubs",
        "aria-label": "kcd learning clubs permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`KCD Learning Clubs`}</h1>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "430px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/39edf9a49f8568c9af92c7726ab5a14d/40c27/learning.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.67567567567568%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAABAAD/8QAFgEBAQEAAAAAAAAAAAAAAAAAAwEC/9oADAMBAAIQAxAAAAEasEYusaNf/8QAHBAAAQUAAwAAAAAAAAAAAAAAAwABAhESEyEi/9oACAEBAAEFAhEnxOSdb6DHw4qWV//EABcRAQADAAAAAAAAAAAAAAAAAAAREmH/2gAIAQMBAT8BtiX/xAAWEQEBAQAAAAAAAAAAAAAAAAAAARP/2gAIAQIBAT8BZx//xAAbEAACAwADAAAAAAAAAAAAAAAAAQIRIRIxgf/aAAgBAQAGPwLpNXTFxoVmYWpIxS8Z/8QAGhABAAMBAQEAAAAAAAAAAAAAAQARIUExkf/aAAgBAQABPyFaQEWxVTL48IHRqcnnWtb7ENYXBt/AhP/aAAwDAQACAAMAAAAQM+//xAAZEQACAwEAAAAAAAAAAAAAAAABEQAhQcH/2gAIAQMBAT8QcNsa2EXXJ//EABcRAQEBAQAAAAAAAAAAAAAAAAEAIRH/2gAIAQIBAT8QeuEJrf/EABsQAQEAAwEBAQAAAAAAAAAAAAERACExYUGh/9oACAEBAAE/EFu0RVbxg+fudbcIMhpS4Fi2GNYYT6XNryfMj4dGLr3G3KMdwhwRc//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "I freaking love learning",
            "title": "I freaking love learning",
            "src": "/static/39edf9a49f8568c9af92c7726ab5a14d/40c27/learning.jpg",
            "srcSet": ["/static/39edf9a49f8568c9af92c7726ab5a14d/8356d/learning.jpg 259w", "/static/39edf9a49f8568c9af92c7726ab5a14d/40c27/learning.jpg 430w"],
            "sizes": "(max-width: 430px) 100vw, 430px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`A `}<strong parentName="p">{`KCD Learning Club`}</strong>{` is a group of 5-12 people who are going through
`}<a parentName="p" {...{
        "href": "/courses"
      }}>{`my coursework`}</a>{` (or anything else) together. Research suggests that we
learn better when we learn together, so that's the goal of a KCD Learning Club:
it's a place for a group of people who want to learn the same thing to do so
together.`}</p>
    <h2 {...{
      "id": "why-join-a-learning-club",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#why-join-a-learning-club",
        "aria-label": "why join a learning club permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Why join a learning club?`}</h2>
    <ol>
      <li parentName="ol">{`You'll learn what you want to learn better than if you try on your own.`}</li>
      <li parentName="ol">{`Develop friendships with other nice learners.`}</li>
      <li parentName="ol">{`You'll get a special place to meet virtually (both async text and sync video)
on `}<a parentName="li" {...{
          "href": "/discord"
        }}>{`the KCD Discord community`}</a>{`.`}</li>
      <li parentName="ol">{`You have access to me (Kent) during twice-weekly
`}<a parentName="li" {...{
          "href": "/office-hours"
        }}>{`office hours`}</a>{` to ask questions.`}</li>
    </ol>
    <h2 {...{
      "id": "starting-a-learning-club",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#starting-a-learning-club",
        "aria-label": "starting a learning club permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Starting a learning club`}</h2>
    <p>{`If you'd like to start a club, then you need:`}</p>
    <ul>
      <li parentName="ul">{`Determine what you'd like to learn specifically`}</li>
      <li parentName="ul">{`4-11 other people who would like to learn that same thing`}</li>
      <li parentName="ul">{`1 person to volunteer to be the `}<a parentName="li" {...{
          "href": "#learning-club-captain"
        }}>{`club captain`}</a></li>
      <li parentName="ul">{`A `}<a parentName="li" {...{
          "href": "#learning-club-schedule"
        }}>{`club schedule`}</a></li>
    </ul>
    <p>{`If you don't already have a group of people who want to learn together, I'll
help you find them.`}</p>
    <p>{`Send Kent your club application
(`}<a parentName="p" {...{
        "href": "https://docs.google.com/document/d/1IcYCa5q3YpnxyC3foPAiOi059EVs7TgoVau65RcQTQo/edit"
      }}>{`here's an example`}</a>{`)
as a Google Doc with the following information:`}</p>
    <ol>
      <li parentName="ol">{`Club name (can be anything)`}</li>
      <li parentName="ol">{`Learning goal`}</li>
      <li parentName="ol">{`Schedule (the topic for at least 4 meetings)`}</li>
      <li parentName="ol">{`Club captain name and email address`}</li>
      <li parentName="ol">{`Club members (names and email addresses)`}</li>
    </ol>
    <p>{`If your club application doesn't have enough members yet, send it along anyway
and Kent will help you find more members.`}</p>
    <h2 {...{
      "id": "learning-club-captain",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#learning-club-captain",
        "aria-label": "learning club captain permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Learning Club Captain`}</h2>
    <p>{`One of the volunteers is responsible for putting together a schedule for when
and how the club will interact and what activities they will participate in to
learn together. They're responsible for leading the club meetings and ensuring
the club is accomplishing its learning goals.`}</p>
    <h2 {...{
      "id": "learning-club-meetings",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#learning-club-meetings",
        "aria-label": "learning club meetings permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Learning Club Meetings`}</h2>
    <p>{`Here's an example meeting agenda:`}</p>
    <ol>
      <li parentName="ol">{`Greetings`}</li>
      <li parentName="ol">{`Review deliverables from the previous meeting`}</li>
      <li parentName="ol">{`Discuss any relevant learnings since the last meeting`}</li>
      <li parentName="ol">{`Participate in a "learning activity"`}</li>
      <li parentName="ol">{`Discuss any learnings and ask/answer questions`}</li>
      <li parentName="ol">{`Determine the deliverables for the next meeting`}</li>
    </ol>
    <p>{`Someone should make notes of any group questions to take to the next KCD Office
Hours.`}</p>
    <blockquote>
      <p parentName="blockquote">{`While meeting synchronously over video is preferred, it's not always possible
for everyone to meet synchronously due to timezone differences. A club can
agree to conduct all meetings asynchronously in the text chat if they must.`}</p>
    </blockquote>
    <h3 {...{
      "id": "learning-activities",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#learning-activities",
        "aria-label": "learning activities permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Learning Activities`}</h3>
    <p>{`The learning activity can happen as an activity you do together during the
meeting, or as something that everyone is expected to do before the meeting.`}</p>
    <p>{`Examples of activities:`}</p>
    <ul>
      <li parentName="ul">{`Watch a video`}</li>
      <li parentName="ul">{`Read a blog post`}</li>
      <li parentName="ul">{`Build a demo`}</li>
    </ul>
    <p>{`These should expose people to new ideas or think critically about concepts their
already familiar with.`}</p>
    <h3 {...{
      "id": "learning-discussion",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#learning-discussion",
        "aria-label": "learning discussion permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Learning Discussion`}</h3>
    <p>{`The core of the club meeting is discussion of the learning activity. This is the
opportunity for everyone to ask questions and provide answers about the new
ideas and concepts. It will allow people the opportunity to think critically
about their understanding of the material.`}</p>
    <h2 {...{
      "id": "learning-club-schedule",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#learning-club-schedule",
        "aria-label": "learning club schedule permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Learning Club Schedule`}</h2>
    <p>{`The schedule is a simple list of the order of concepts you'll be learning. If
you're planning to go through `}<a parentName="p" {...{
        "href": "/courses"
      }}>{`one of my courses`}</a>{`, then it can simply
be a list of the videos with a date associated. It could also be a series of
blog posts you want to read and discuss together. In that case it would be a
list of the posts you want to discuss.`}</p>
    <p>{`The schedule doesn't have to be set in stone (it can be flexible) and the club
captain doesn't have to decide everything on their own (this can be
collaborative). It simply ensures that everyone in the club is on the same page.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      